import Service from '../../API/service';
import { AuthRequest, PassChange } from '../../types/Auth';
import { AppDispatch } from '../store';
import { Actions as MainActions } from './MainSlice';
import { Actions as AuthActions } from './AuthSlice';
import { CardItem, Item, RealmItem } from '../../types/Main';

export const fetchUser = () => async (dispatch: AppDispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
    dispatch(AuthActions.fetchingSuccess());
  }
};

export const loginUser = (data: AuthRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(AuthActions.fetching());
    const response = await Service.login(data);
    localStorage.setItem('token', response.data.access_token);
    dispatch(AuthActions.fetchingSuccess());
  } catch (e: any) {
    dispatch(AuthActions.fetchingError(e.response?.data?.message || 'Error fetching'));
  }
};

export const logoutUser = () => async (dispatch: AppDispatch) => {
  localStorage.removeItem('token');
  dispatch(AuthActions.reset());
};

export const changePass = (data: PassChange) => async (dispatch: AppDispatch) => {
  try {
    await Service.changePass(data);
    dispatch(AuthActions.successSync());
  } catch (e: any) {
    dispatch(AuthActions.failedSync(e.response?.data?.message));
  }
};

export const getMainData = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(MainActions.fetching());
    const responseHome = await Service.getHome();
    dispatch(MainActions.fetchingHome(responseHome.data));
    const responseCardsPage = await Service.getCardsPage();
    dispatch(MainActions.fetchingCardsPage(responseCardsPage.data));
    const responseRealmsPage = await Service.getRealmsPage();
    dispatch(MainActions.fetchingRealmsPage(responseRealmsPage.data));
    const responseCardItems = await Service.getCardItems();
    dispatch(MainActions.fetchingCardItems(responseCardItems.data));
    const responseRealmItems = await Service.getRealmItems();
    dispatch(MainActions.fetchingRealmItems(responseRealmItems.data));
    dispatch(MainActions.fetchingSuccess());
  } catch (e: any) {
    dispatch(MainActions.fetchingError(e.response?.data?.message || 'Error fetching'));
  }
};

export const updateHome = (data: Item) => async (dispatch: AppDispatch) => {
  try {
    await Service.setHome(data);
    dispatch(MainActions.updateHome(data));
    dispatch(MainActions.successSync());
  } catch (e: any) {
    dispatch(MainActions.failedSync(e.response?.data?.message));
  }
};
export const updateCardsPage = (data: Item) => async (dispatch: AppDispatch) => {
  try {
    await Service.setCardsPage(data);
    dispatch(MainActions.updateCardsPage(data));
    dispatch(MainActions.successSync());
  } catch (e: any) {
    dispatch(MainActions.failedSync(e.response?.data?.message));
  }
};
export const updateRealmsPage = (data: Item) => async (dispatch: AppDispatch) => {
  try {
    await Service.setRealmsPage(data);
    dispatch(MainActions.updateRealmsPage(data));
    dispatch(MainActions.successSync());
  } catch (e: any) {
    dispatch(MainActions.failedSync(e.response?.data?.message));
  }
};
export const updateCardItems = (data: CardItem, image?: File) => async (dispatch: AppDispatch) => {
  try {
    const response = await Service.setCardItems(data, image);
    dispatch(MainActions.updateCardItems(response.data));
    dispatch(MainActions.successSync());
  } catch (e: any) {
    dispatch(MainActions.failedSync(e.response?.data?.message));
  }
};
export const updateRealmItems = (data: RealmItem) => async (dispatch: AppDispatch) => {
  try {
    await Service.setRealmItems(data);
    dispatch(MainActions.updateRealmItems(data));
    dispatch(MainActions.successSync());
  } catch (e: any) {
    dispatch(MainActions.failedSync(e.response?.data?.message));
  }
};
