import React, { useState } from 'react';
import styled from 'styled-components';
import { InputAdornment, IconButton, TextField, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { loginUser } from '../store/reducers/ActionCreators';

export const Auth = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useAppSelector(state => state.authReducer);

  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  return (
    <StyledForm>
      <TextField
        type='email'
        value={values.email}
        onChange={handleChange('email')}
        margin='normal'
        fullWidth
        label='Email'
      />

      <TextField
        value={values.password}
        onChange={handleChange('password')}
        type={showPassword ? 'text' : 'password'}
        margin='normal'
        fullWidth
        label='Password'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={e => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge='end'>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button
        onClick={() => dispatch(loginUser(values))}
        disabled={isLoading ? true : false}
        className='button'
        variant='contained'>
        {isLoading ? <CircularProgress /> : 'Log in'}
      </Button>
    </StyledForm>
  );
};

const StyledForm = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .button {
    overflow: hidden;
    font-size: 20px;
    width: 100%;
    height: 50px;
    margin-top: 10px;
  }
`;
