import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Item, CardItem, RealmItem, MainState } from '../../types/Main';

const initialState: MainState = {
  home: [],
  cardsPage: [],
  realmsPage: [],
  cardItems: [],
  realmItems: [],
  filter: '',
  error: '',
  isLoading: false,
  successSync: '',
  failedSync: ''
};

const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    fetching: state => {
      state.isLoading = true;
    },
    fetchingSuccess: state => {
      state.isLoading = false;
      state.error = '';
      state.filter = '';
    },
    fetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      console.error('Error fetching lesson');
    },
    successSync: state => {
      state.successSync = 'Successfully saved';
    },
    failedSync: (state, action: PayloadAction<string | undefined>) => {
      state.failedSync = action.payload || 'Saving failed';
    },
    clearSyncMessage: state => {
      state.successSync = '';
      state.failedSync = '';
    },
    fetchingHome: (state, action: PayloadAction<Item[]>) => {
      state.home = action.payload;
    },
    updateHome: (state, action: PayloadAction<Item>) => {
      const { id } = action.payload;

      state.home = [
        ...state.home.map((item: Item) => {
          if (item.id === id) {
            return { ...action.payload };
          }
          return item;
        })
      ];
    },
    updateCardsPage: (state, action: PayloadAction<Item>) => {
      const { id } = action.payload;

      state.cardsPage = [
        ...state.cardsPage.map((item: Item) => {
          if (item.id === id) {
            return { ...action.payload };
          }
          return item;
        })
      ];
    },
    updateRealmsPage: (state, action: PayloadAction<Item>) => {
      const { id } = action.payload;

      state.realmsPage = [
        ...state.realmsPage.map((item: Item) => {
          if (item.id === id) {
            return { ...action.payload };
          }
          return item;
        })
      ];
    },
    updateCardItems: (state, action: PayloadAction<CardItem>) => {
      const { id } = action.payload;

      state.cardItems = [
        ...state.cardItems.map((item: CardItem) => {
          if (item.id === id) {
            return { ...action.payload };
          }
          return item;
        })
      ];
    },
    updateRealmItems: (state, action: PayloadAction<RealmItem>) => {
      const { id } = action.payload;

      state.realmItems = [
        ...state.realmItems.map((item: RealmItem) => {
          if (item.id === id) {
            return { ...action.payload };
          }
          return item;
        })
      ];
    },
    fetchingCardsPage: (state, action: PayloadAction<Item[]>) => {
      state.cardsPage = action.payload;
    },
    fetchingRealmsPage: (state, action: PayloadAction<Item[]>) => {
      state.realmsPage = action.payload;
    },
    fetchingCardItems: (state, action: PayloadAction<CardItem[]>) => {
      state.cardItems = action.payload;
    },
    fetchingRealmItems: (state, action: PayloadAction<RealmItem[]>) => {
      state.realmItems = action.payload;
    },
    reset: () => initialState
  }
});

export default slice.reducer;
export const Actions = slice.actions;
