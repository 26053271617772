import React, { FC, useState, useEffect, useMemo } from 'react';
import { CardItem } from '../../types/Main';
import { Card, CardContent, Typography } from '@mui/material';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { ImageUpload } from './ImageUpload';

interface Props {
  data: CardItem;
  save: (data: CardItem, image?: File) => void;
}

export const CardItemComponent: FC<Props> = props => {
  const { data } = props;
  const [formData, setFormData] = useState(data);
  const [disableButton, setDisableButton] = useState(true);
  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    setDisableButton(JSON.stringify(data) === JSON.stringify(formData));
  }, [data, formData]);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const memoImage = useMemo(() => {
    return image !== null ? image : data.background;
  }, [image, data.background]);

  const uploadImage = (img: File) => {
    setFormData({
      ...formData,
      background: img.name
    });
    setImage(img);
  };

  function save() {
    if (image) {
      props.save(formData, image);
    } else {
      props.save(formData);
    }
  }

  function reset() {
    setImage(null);
    setFormData(data);
  }

  return (
    <Card sx={{ minWidth: 275, mb: 2 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          #{data.id}
        </Typography>

        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md='auto'>
              <ImageUpload
                id={`file_uploader_${formData.id}`}
                imgSrc={memoImage}
                onChange={uploadImage}
              />
            </Grid>
            <Grid item xs={12} md>
              <Typography variant='subtitle1'>Gift</Typography>
              <TextField
                margin='normal'
                fullWidth
                label='Title'
                onChange={e =>
                  setFormData({
                    ...formData,
                    gift: {
                      ...formData.gift,
                      title: e.target.value
                    }
                  })
                }
                value={formData.gift.title}
              />
              <TextField
                margin='normal'
                label='Text'
                value={formData.gift.text}
                onChange={e =>
                  setFormData({
                    ...formData,
                    gift: {
                      ...formData.gift,
                      text: e.target.value
                    }
                  })
                }
                fullWidth
                multiline
                rows={5}
              />
              <Typography variant='subtitle1'>Shadow</Typography>
              <TextField
                margin='normal'
                fullWidth
                label='Title'
                value={formData.shadow.title}
                onChange={e =>
                  setFormData({
                    ...formData,
                    shadow: {
                      ...formData.shadow,
                      title: e.target.value
                    }
                  })
                }
              />
              <TextField
                margin='normal'
                label='Text'
                value={formData.shadow.text}
                onChange={e =>
                  setFormData({
                    ...formData,
                    shadow: {
                      ...formData.shadow,
                      text: e.target.value
                    }
                  })
                }
                fullWidth
                multiline
                rows={5}
              />
              <Typography variant='subtitle1'>Common</Typography>
              <TextField
                margin='normal'
                fullWidth
                label='Themes'
                value={formData.themes}
                onChange={e =>
                  setFormData({
                    ...formData,
                    themes: e.target.value
                  })
                }
              />
            </Grid>
          </Grid>

          <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
            <Button variant='contained' color='success' disabled={disableButton} onClick={save}>
              Save
            </Button>
            <Button disabled={disableButton} onClick={reset} variant='contained'>
              Reset
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
