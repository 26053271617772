import React, { FC } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { Item } from '../../types/Main';
import { useSimplySearch } from '../../hooks/search';
import { SimpleItem } from './SimpleItem';

interface Props {
  name: string;
  list: Item[];
  save: (data: Item) => void;
}

export const SimpleList: FC<Props> = props => {
  const { filter } = useAppSelector(state => state.mainReducer);
  const filterList = useSimplySearch(filter, props.list);

  return (
    <>
      {filterList.map((item: Item) => (
        <SimpleItem
          key={`${props.name}_${item.id}`}
          id={item.id}
          text={item.text}
          save={props.save}
        />
      ))}
    </>
  );
};
