import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '65px',
          '&.MuiTableCell-head': {
            fontWeight: 800
          }
        }
      }
    }
  }
});
