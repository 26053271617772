import React, { FC, useState, useEffect } from 'react';
import { Item } from '../../types/Main';
import { Card, CardContent, Typography } from '@mui/material';
import { Box, Button, Stack, TextField } from '@mui/material';

interface Props {
  id: number;
  text: string;
  save: (data: Item) => void;
}

export const SimpleItem: FC<Props> = props => {
  const [text, setText] = useState<string>(props.text);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    setDisableButton(props.text === text);
  }, [text, props.text]);

  return (
    <Card sx={{ minWidth: 275, mb: 2 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          #{props.id}
        </Typography>

        <Box my={2}>
          <TextField
            fullWidth
            multiline
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setText(event.target.value)
            }
            label='Text'
            value={text}
          />
          <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
            <Button
              onClick={() =>
                props.save({
                  id: props.id,
                  text: text
                })
              }
              variant='contained'
              disabled={disableButton}
              color='success'>
              Save
            </Button>
            <Button
              disabled={disableButton}
              onClick={() => setText(props.text)}
              variant='contained'>
              Reset
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
