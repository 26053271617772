import React, { FC, useEffect, useState } from 'react';
import { RealmItem } from '../../types/Main';
import { Card, CardContent, Typography } from '@mui/material';
import { Box, Button, Stack, TextField } from '@mui/material';
import styled from 'styled-components';

interface Props {
  data: RealmItem;
  save: (data: RealmItem) => void;
}

export const RealmItemComponent: FC<Props> = props => {
  const { data } = props;
  const [name, setName] = useState<string>(data.name);
  const [textList, setTextList] = useState<string[]>(data.text);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    setDisableButton(data.name === name && JSON.stringify(textList) === JSON.stringify(data.text));
  }, [name, textList, data.name, data.text]);

  return (
    <Card sx={{ minWidth: 275, mb: 2 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          #{data.id}
        </Typography>

        <Box my={2}>
          <StyledTextField
            fullWidth
            label='Title'
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setName(event.target.value)
            }
            value={name}
          />
          {textList.map((str: string, index: number) => (
            <StyledTextField
              key={`realm_item_${index}`}
              fullWidth
              multiline
              label={`Text ${index + 1}`}
              value={textList[index]}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setTextList(
                  [...textList].map((item: string, i: number) => {
                    if (i === index) {
                      return event.target.value;
                    } else {
                      return item;
                    }
                  })
                );
              }}
            />
          ))}
          <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
            <Button
              variant='contained'
              color='success'
              disabled={disableButton}
              onClick={() => props.save({ ...data, name: name, text: textList })}>
              Save
            </Button>
            <Button
              disabled={disableButton}
              onClick={() => {
                setTextList(data.text);
                setName(data.name);
              }}
              variant='contained'>
              Reset
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin: 10px 0;
  }
`;
