import React from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { AppRouter } from './components/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { fetchUser } from './store/reducers/ActionCreators';
import { useAppDispatch } from './hooks/redux';

function App() {
  const dispatch = useAppDispatch();

  dispatch(fetchUser());

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
