import React from 'react';
import { Auth } from '../pages/Auth';
import { Main } from '../pages/Main';
import { ChangePass } from '../pages/ChangePass';

export interface IRoute {
  path: string;
  component: React.ComponentType;
}

export enum RouteNames {
  CHANGE_PASS = '/change-pass',
  AUTH = '/auth',
  MAIN = '/'
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.AUTH,
    component: Auth
  }
];

export const privateRoutes: IRoute[] = [
  { path: RouteNames.MAIN, component: Main },
  {
    path: RouteNames.CHANGE_PASS,
    component: ChangePass
  }
];
