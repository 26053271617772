import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { CardItem, Item, RealmItem } from '../../types/Main';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { CardList } from './CardList';
import { RealmList } from './RealmList';
import { Search } from '../../components/Search';
import { useNavigate } from 'react-router-dom';
import {
  getMainData,
  updateHome,
  updateCardsPage,
  updateRealmsPage,
  updateCardItems,
  updateRealmItems,
  logoutUser
} from '../../store/reducers/ActionCreators';
import { Actions as MainActions } from '../../store/reducers/MainSlice';
import { SimpleList } from './SimpleList';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';

export const Main = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isLoading,
    successSync,
    failedSync,
    filter,
    home,
    cardsPage,
    realmsPage,
    cardItems,
    realmItems
  } = useAppSelector(state => state.mainReducer);
  const [value, setValue] = React.useState('1');
  const syncRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      dispatch(MainActions.clearSyncMessage());
    }, 5000);
  }, [successSync, failedSync, dispatch]);

  useEffect(() => {
    if (syncRef.current === true) {
      return;
    }

    syncRef.current = true;
    dispatch(getMainData());
  }, [dispatch]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(MainActions.setFilter(''));
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return (
      <StyledBox>
        <CircularProgress />
      </StyledBox>
    );
  }

  return (
    <Box>
      <TabContext value={value}>
        <Box
          sx={{
            m: 2,
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <TabList
            orientation='horizontal'
            variant='scrollable'
            onChange={handleChange}
            aria-label='horizontal menu'>
            <StyledTab label='Home Page' value='1' />
            <StyledTab label='Cards Page' value='2' />
            <StyledTab label='Realms Page' value='3' />
            <StyledTab label='Card Items' value='4' />
            <StyledTab label='Realm Items' value='5' />
          </TabList>
          <Box>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'>
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem onClick={() => navigate('/change-pass')}>Change Password</MenuItem>
              <MenuItem onClick={() => dispatch(logoutUser())}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
        <Search
          search={filter}
          onSearch={(value: string) => dispatch(MainActions.setFilter(value))}
        />
        <TabPanel value='1'>
          <SimpleList name='home' list={home} save={(data: Item) => dispatch(updateHome(data))} />
        </TabPanel>
        <TabPanel value='2'>
          <SimpleList
            name='cardsPage'
            list={cardsPage}
            save={(data: Item) => dispatch(updateCardsPage(data))}
          />
        </TabPanel>
        <TabPanel value='3'>
          <SimpleList
            name='realmsPage'
            list={realmsPage}
            save={(data: Item) => dispatch(updateRealmsPage(data))}
          />
        </TabPanel>
        <TabPanel value='4'>
          <CardList
            name='cardItems'
            list={cardItems}
            save={(data: CardItem, image?: File) => dispatch(updateCardItems(data, image))}
          />
        </TabPanel>
        <TabPanel value='5'>
          <RealmList
            name='realmItems'
            list={realmItems}
            save={(data: RealmItem) => dispatch(updateRealmItems(data))}
          />
        </TabPanel>
      </TabContext>

      {successSync && (
        <Snackbar open={true}>
          <Alert severity='success' sx={{ width: '100%' }}>
            {successSync}
          </Alert>
        </Snackbar>
      )}
      {failedSync && (
        <Snackbar open={true}>
          <Alert severity='error' sx={{ width: '100%' }}>
            {failedSync}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTab = styled(Tab)`
  &.MuiButtonBase-root.MuiTab-root {
    white-space: nowrap;
    align-items: flex-start;
  }
`;
