import React, { FC } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { CardItem } from '../../types/Main';
import { useCardSearch } from '../../hooks/search';
import { CardItemComponent } from './CardItem';

interface Props {
  name: string;
  list: CardItem[];
  save: (data: CardItem, image?: File) => void;
}

export const CardList: FC<Props> = props => {
  const { filter } = useAppSelector(state => state.mainReducer);
  const filterList = useCardSearch(filter, props.list);

  return (
    <>
      {filterList.map((item: CardItem) => (
        <CardItemComponent key={`${props.name}_${item.id}`} data={item} save={props.save} />
      ))}
    </>
  );
};
