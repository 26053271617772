import { Item, CardItem, RealmItem } from '../types/Main';

export const useSimplySearch = (searchStr: string, data: Item[]) => {
  let result = data.filter((item: Item) => {
    return item.text.toLowerCase().includes(searchStr.toLowerCase());
  });

  return result;
};

export const useCardSearch = (searchStr: string, data: CardItem[]) => {
  let result = data.filter((item: CardItem) => {
    return (
      item.gift.title.toLowerCase().includes(searchStr.toLowerCase()) ||
      item.gift.text.toLowerCase().includes(searchStr.toLowerCase()) ||
      item.shadow.title.toLowerCase().includes(searchStr.toLowerCase()) ||
      item.shadow.text.toLowerCase().includes(searchStr.toLowerCase())
    );
  });
  return result;
};

export const useRealmSearch = (searchStr: string, data: RealmItem[]) => {
  let result = data.filter((item: RealmItem) => {
    return item.name.toLowerCase().includes(searchStr.toLowerCase());
  });

  return result;
};
