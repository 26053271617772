import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { InputAdornment, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Actions as AuthAction } from '../store/reducers/AuthSlice';
import { changePass, logoutUser } from '../store/reducers/ActionCreators';

export const ChangePass = () => {
  const dispatch = useAppDispatch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { passChangeFailed, passChangeSuccess } = useAppSelector(state => state.authReducer);
  const [values, setValues] = useState({
    old_password: '',
    password: '',
    password_confirm: ''
  });

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(AuthAction.clearSyncMessage());
    }, 3000);

    if (passChangeSuccess) {
      setTimeout(() => {
        dispatch(AuthAction.clearSyncMessage());
        dispatch(logoutUser());
      }, 3000);
    }
  }, [passChangeFailed, passChangeSuccess, dispatch]);

  return (
    <Box
      sx={{
        maxWidth: '500px',
        margin: '50px auto'
      }}>
      <h2>Change password</h2>
      <TextField
        margin='normal'
        required
        fullWidth
        label='Old Password'
        type={showOldPassword ? 'text' : 'password'}
        onChange={handleChange('old_password')}
        autoComplete='off'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={e => setShowOldPassword(!showOldPassword)}
                edge='end'>
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        margin='normal'
        required
        fullWidth
        label='New Password'
        type={showNewPassword ? 'text' : 'password'}
        onChange={handleChange('password')}
        autoComplete='off'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={e => setShowNewPassword(!showNewPassword)}
                edge='end'>
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        margin='normal'
        required
        fullWidth
        label='Repeat New Password'
        type={showConfirmPassword ? 'text' : 'password'}
        onChange={handleChange('password_confirm')}
        autoComplete='off'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={e => setShowConfirmPassword(!showConfirmPassword)}
                edge='end'>
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Button
        onClick={() => dispatch(changePass(values))}
        fullWidth
        variant='contained'
        sx={{ mt: 3, mb: 2 }}>
        Change Password
      </Button>

      {passChangeSuccess && (
        <Snackbar open={true}>
          <Alert severity='success' sx={{ width: '100%' }}>
            {passChangeSuccess}
          </Alert>
        </Snackbar>
      )}
      {passChangeFailed && (
        <Snackbar open={true}>
          <Alert severity='error' sx={{ width: '100%' }}>
            {passChangeFailed}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};
