import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes, RouteNames } from '../router';
import { useAppSelector } from '../hooks/redux';

export const AppRouter = () => {
  const { isAuth } = useAppSelector(state => state.authReducer);

  return isAuth ? (
    <Routes>
      {privateRoutes.map(route => (
        <Route key={route.path} element={<route.component />} path={route.path} />
      ))}
      <Route path='*' element={<Navigate to={RouteNames.MAIN} />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map(route => (
        <Route key={route.path} element={<route.component />} path={route.path} />
      ))}
      <Route path='*' element={<Navigate to={RouteNames.AUTH} />} />
    </Routes>
  );
};
