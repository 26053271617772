import React, { FC } from 'react';
import { Card, CardContent, FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  search: string;
  onSearch: (value: string) => void;
}

export const Search: FC<Props> = props => {
  const { search, onSearch } = props;

  return (
    <Card sx={{ minWidth: 275, m: 3, mb: 0 }}>
      <CardContent>
        <FormControl fullWidth variant='standard'>
          <InputLabel htmlFor='search-input'>Search</InputLabel>
          <Input
            autoComplete='off'
            id='search-input'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearch(e.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </CardContent>
    </Card>
  );
};
