import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, CircularProgress, Modal, Typography } from '@mui/material';
import styled from 'styled-components';

interface Props {
  onChange: (value: any) => void;
  id: string;
  imgSrc: File | string;
}

export const ImageUpload: FC<Props> = props => {
  const { imgSrc, onChange, id } = props;

  const [image, setImage] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (typeof imgSrc === 'string') {
      setImage(imgSrc);
    } else {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(imgSrc);
      fileReader.onprogress = () => {
        setLoading(true);
      };
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        if (e?.target?.result) {
          setImage(e?.target?.result.toString());
        }
        setLoading(false);
      };
      fileReader.onerror = err => {
        setError(true);
        setLoading(false);
        setImage('');
      };
    }
  }, [imgSrc]);

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (!target?.files) return;

    const file = target.files[0];
    if (file) {
      onChange(file);
    }
  };

  const handleModalOpen = () => {
    if (!image) return;

    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <Box component='div'>
      <Box
        onClick={handleModalOpen}
        sx={{
          width: 153,
          height: 249,
          background: `url(${image})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          bgcolor: '#eee',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}>
        {error && <Typography color='red'>Image uploading error</Typography>}
      </Box>

      <input
        accept='image/*'
        style={{ display: 'none' }}
        id={id || 'raised-button-file'}
        multiple
        onChange={onUpload}
        type='file'
      />
      <label htmlFor={id || 'raised-button-file'}>
        <Button size='small' component='span'>
          {loading ? <CircularProgress /> : 'Upload new photo'}
        </Button>
      </label>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <StyledBox>
          <img
            style={{ display: 'block', maxWidth: '100%', maxHeight: '80vh' }}
            src={image}
            alt=''
          />
        </StyledBox>
      </Modal>
    </Box>
  );
};

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: 'background.paper';
  box-shadow: 24;
`;
