import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isAuth: boolean;
  error: string;
  isLoading: boolean;
  passChangeSuccess: string;
  passChangeFailed: string;
}

const initialState: AuthState = {
  isAuth: false,
  error: '',
  isLoading: false,
  passChangeSuccess: '',
  passChangeFailed: ''
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetching: state => {
      state.isLoading = true;
    },
    fetchingSuccess: state => {
      state.isLoading = false;
      state.error = '';
      state.isAuth = true;
    },
    fetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      console.error('Error fetching');
    },
    successSync: state => {
      state.passChangeSuccess = 'Password Changed Successfully!';
    },
    failedSync: (state, action: PayloadAction<string | undefined>) => {
      state.passChangeFailed = action.payload || 'Password Change Failed';
    },
    clearSyncMessage: state => {
      state.passChangeSuccess = '';
      state.passChangeFailed = '';
    },
    reset: () => initialState
  }
});

export default slice.reducer;
export const Actions = slice.actions;
