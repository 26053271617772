import { AxiosPromise } from 'axios';
import { AuthRequest, PassChange } from '../types/Auth';
import { Item, CardItem, RealmItem } from '../types/Main';

import $api, { API_URL } from './http';

export default class Service {
  static loginWithGoogle() {
    document.location.href = `${API_URL}/auth/google`;
  }

  static async checkUser(): Promise<AxiosPromise> {
    return await $api.get('/api/refresh');
  }

  static async login(data: AuthRequest): Promise<AxiosPromise> {
    return await $api.post('/api/login', data);
  }

  static async getHome(): Promise<AxiosPromise> {
    return await $api.get('/api/home');
  }

  static async getCardsPage(): Promise<AxiosPromise> {
    return await $api.get('/api/cards-page');
  }

  static async getRealmsPage(): Promise<AxiosPromise> {
    return await $api.get('/api/realms-page');
  }

  static async getCardItems(): Promise<AxiosPromise> {
    return await $api.get('/api/card-items');
  }

  static async getRealmItems(): Promise<AxiosPromise> {
    return await $api.get('/api/realm-items');
  }

  static async setHome(data: Item): Promise<AxiosPromise> {
    return await $api.post(`/api/home/${data.id}`, { text: data.text });
  }

  static async setCardsPage(data: Item): Promise<AxiosPromise> {
    return await $api.post(`/api/cards-page/${data.id}`, { text: data.text });
  }

  static async setRealmsPage(data: Item): Promise<AxiosPromise> {
    return await $api.post(`/api/realms-page/${data.id}`, { text: data.text });
  }

  static async setCardItems(data: CardItem, image?: File): Promise<AxiosPromise> {
    const postData: any = {
      id: data.id,
      gift_title: data.gift.title,
      gift_text: data.gift.text,
      shadow_title: data.shadow.title,
      shadow_text: data.shadow.text,
      themes: data.themes
    };

    if (image) {
      postData.background = image;
    }

    return await $api.post(`/api/card-items/${data.id}`, postData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static async setRealmItems(data: RealmItem): Promise<AxiosPromise> {
    return await $api.post(`/api/realm-items/${data.id}`, {
      id: data.id,
      name: data.name,
      text: data.text,
      image: data.image,
      popupImage: data.popupImage,
      positionX: data.positionX,
      positionY: data.positionY
    });
  }

  static changePass(data: PassChange): Promise<AxiosPromise> {
    return $api.post('/api/change-password', data);
  }

  static logout(): Promise<AxiosPromise> {
    return $api.get('/api/logout');
  }
}
